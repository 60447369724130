import * as React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import "./button.scss"
import {motion} from "framer-motion"

const StyledButton = styled(motion.button)`
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding: 0px 24px 0px 24px;
    overflow: hidden;
    align-content: center;
    flex-wrap: nowrap;
    gap: 0;
    border-radius: 144px;
    border: 5px solid #222;
    font-size: 32px;
    font-weight: bold;
    background: var(--color-primary);
    color: white;
    font-family: "Kulim Park", serif;
    transition: background 90ms ease-in-out;
    &:hover {
        background: white;
        color: black;
        transition: background 90ms ease-in-out;
    }
`

export default function Button(){
    return ( 
    <>
     <StyledButton initial={{ opacity: 0, y: 32 }}
    whileInView={{ opacity: 1, y: 0, transition: {delay: 0.1} }}
    animate={{ opacity: 0, y: 32 }} onClick={() => navigate("/contact/")}>RSVP</StyledButton> 
    </>
    )
}