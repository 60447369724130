import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import styled from "styled-components"
import Button from "../components/button"
import "./index.scss"
import Markdown from "markdown-to-jsx"
import { content } from "../components/content.js"
import { useInView, motion } from "framer-motion"
import { fadeIn, draw, fadeInUp } from "../components/animation"

const bandList = [
  {
    name: "Guerz/Floor Funktion",
    time: "2:00",
    artists: [
      {
        name: "Guerz",
        url: "https://backandforthforeverandever.bandcamp.com/",
      },
      { name: "Floor Funktion" },
    ],
  },
  {
    name: "THESAURUS REX",
    time: "3:15",
    artists: [{ name: "THESAURUS REX", url: "http://www.thesaurusrexband.com" }],
  },
  {
    name: "MILES STRAND OF OUR FEARS",
    time: "4:00",
    artists: [
      {
        name: "MILES STRAND OF OUR FEARS",
        url: "https://ourfears.bandcamp.com/",
      },
    ],
  },
  {
    name: "KID AUDRA",
    time: "4:45",
    artists: [{ name: "KID AUDRA", url: "https://tegaband.bandcamp.com/" }],
  },
  {
    name: "TEGA", 
    time: "5:30",
    artists: [
      {
        name: "TEGA",
        url: "https://planningforburial.bandcamp.com/",
      },
    ],
  },
  {
    name: "TRON THE ARCHER",
    time: "6:00",
    artists: [
      { name: "TRON THE ARCHER", url: "https://tronthearcher.bandcamp.com/" },
    ],
  },
  {
    name: "A NAVE ESPACIAL",
    time: "6:45",
    artists: [
      { name: "A NAVE ESPACIAL", url: "https://anaveespacial.bandcamp.com/" },
    ],
  },
  {
    name: "Great Ruin",
    time: "8:00",
    artists: [{ name: "Great Ruin", url: "https://soundcloud.com/greatruin" }],
  },
  {
    name: "GUERZ / FLOOR FUNKTION",
    time: "9:00",
    artists: [
      {
        name: "GUERZ",
        url: "https://backandforthforeverandever.bandcamp.com/",
      },
      { name: "FLOOR FUNKTION" },
    ],
  },
]

const artistList = [
  {
    artists: [{ name: "Chris Kursel", url: "https://chriskurselart.com/" }],
  },
  {
    artists: [
      { name: "Joan Pope", url: "https://templeovsaturn.myportfolio.com/" },
    ],
  },
  {
    artists: [
      { name: "Lorenzpunk", url: "https://www.instagram.com/lorenzpunk105/" },
    ],
  },
]

const str = `# DatePicker

The DatePicker works by supplying a date to bias towards,
as well as a default timezone.`

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const SectionHeader = styled.div`
  display: flex;
  font-size: 72px;
  text-align: center;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #2b1612;

  @media (max-width: 810px) {
    font-size: 48px;
  }

  @media (max-width: 480px) {
    font-size: 36px;
  }
`
const Date = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 24px;
  text-align: center;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #2b1612;
`

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
 
<motion.div initial={{ opacity: 0, y: 32 }}
    whileInView={{ opacity: 1, y: 0, transition: {delay: 0.1} }}
    animate={{ opacity: 0, y: 32 }} style={{display: "flex", width: "100%"}}>
    <StaticImage
      className="hero"
      imgClassName="heroImage"
      src="../images/moonlanding.png"
      loading="eager"
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt=""
    />
    </motion.div>

    <motion.div className={styles.textCenter} initial={{ opacity: 0, y: 32 }}
    whileInView={{ opacity: 1, y: 0, transition: {delay: 0.1} }}
    animate={{ opacity: 0, y: 32 }}>
      <Date>
        <SectionHeader>OCT 8TH 2022</SectionHeader>
        <p>RSVP for Address</p>
      </Date>
    </motion.div>

    <Button/>

    <motion.div className={styles.textCenter} initial={{ opacity: 0, y: 32 }}
    whileInView={{ opacity: 1, y: 0, transition: {delay: 0.1} }}
    animate={{ opacity: 0, y: 32 }} style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
      <StaticImage
        className="waterfall"
        imgClassName="waterfallImage"
        src="../images/waterfall.png"
        loading="eager"
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        style={{
          marginBottom: `var(--space-3)`,
          width: "100%",
        }}
      />
      <StaticImage
        className="stream"
        imgClassName="streamImage"
        src="../images/stream.png"
        loading="eager"
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        style={{
          marginBottom: `var(--space-3)`,
          width: "100%",
        }}
      />
    </motion.div>

    <motion.div className={styles.textCenter} initial={{ opacity: 0, y: 32 }}
    whileInView={{ opacity: 1, y: 0, transition: {delay: 0.1} }}
    animate={{ opacity: 0, y: 32 }}>
      <SectionHeader>BANDS / DJS</SectionHeader>
    </motion.div>

    <motion.div initial={{ opacity: 0, y: 32 }}
    whileInView={{ opacity: 1, y: 0, transition: {delay: 0.1} }}
    animate={{ opacity: 0, y: 32 }}>
      {bandList.map(band => (
        <Band title={band.name} time={band.time} artists={band.artists} />
      ))}
    </motion.div>

    <motion.div className={styles.textCenter} initial={{ opacity: 0, y: 32 }}
    whileInView={{ opacity: 1, y: 0, transition: {delay: 0.1} }}
    animate={{ opacity: 0, y: 32 }}>
      <SectionHeader>ART / INSTALLATIONS</SectionHeader>
    </motion.div>

    <motion.div initial={{ opacity: 0, y: 32 }}
    whileInView={{ opacity: 1, y: 0, transition: {delay: 0.1} }}
    animate={{ opacity: 0, y: 32 }}>
      {artistList.map(band => (
        <Band title={band.name} artists={band.artists} />
      ))}
    </motion.div>

<motion.div initial={{ opacity: 0, y: 32 }}
    whileInView={{ opacity: 1, y: 0, transition: {delay: 0.1} }}
    animate={{ opacity: 0, y: 32 }}>
    <StaticImage
      className="waterfall"
      imgClassName="fireplaceImage"
      src="../images/fireplace.png"
      loading="eager"
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt=""
      style={{
        marginBottom: `var(--space-3)`,
        width: "100%",
      }}
    />
    </motion.div>

    <motion.div className="faq" initial={{ opacity: 0, y: 32 }}
    whileInView={{ opacity: 1, y: 0, transition: {delay: 0.1} }}
    animate={{ opacity: 0, y: 32 }}>
      <SectionHeader>FAQ</SectionHeader>
      <Markdown options={{ forceBlock: false }}>{content}</Markdown>
    </motion.div>

    <motion.div  initial={{ opacity: 0, y: 32 }}
    whileInView={{ opacity: 1, y: 0, transition: {delay: 0.1} }}
    animate={{ opacity: 0, y: 32 }}className={styles.textCenter}>
      <Date>
        <SectionHeader>OCT 8TH 2022</SectionHeader>
        <p>RSVP for Address</p>
      </Date>
    </motion.div>
    <Button />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage

const BandRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  grid-template-columns: 0.1fr 0.9fr;

  gap: 16px;
  font-size: 48px;
  font-weight: bold;
  padding: 8px 0;
  border-bottom: 3px solid black;
  text-transform: uppercase;
  line-height: 1;

  @media (max-width: 810px) {
    font-size: 36px;
  }

  @media (max-width: 390px) {
    font-size: 24px;
  }
`

const Time = styled.div`
  display: flex;
  min-width: 100px;
`

const Title = styled.div`
  display: flex;
`

export function Band({ title, time, style, url, artists }) {
  // const name = bands[0];
  return (
    <BandRow style={{ ...style }}>
      {time && <Time>{time}</Time>}
      <Title>
        {artists === undefined
          ? "loading..."
          : artists
              .map(band => (
                <span key={band}>
                  {band.url ? (
                    <a target="_blank" href={band.url}>
                      {band.name}
                    </a>
                  ) : (
                    band.name
                  )}
                </span>
              ))
              .reduce((prev, curr) => [prev, " / ", curr])}
      </Title>
    </BandRow>
  )
}
