export const content = `## What is this?

Ortegapalooza is a D.I.Y. music and art festival, generally attended by a hodgepodge of extended friends and family. The friends and artists who are intertwined in our lives graciously bestow upon us their art and musical performances. We are both humbled and honored to be blessed with their beautiful contributions which culminate to a very chill and lovely event.

## When is this?

October 8th, 2022

## How do I get access?

Be invited or be invited by an invitee or a performer.

## Where is this?

The address will be disclosed to those in the know or reach out to us!

## Is it kid friendly?

I can not decide this for you. People will be drinking but generally the people attending are all good people we know and trust or invited by those people. I assume will be respectful and act appropriately enough for children. If you would bring your kids to a public music festival then I would say yes.

## Can I support the artists?

There will be a designated area for donations or merch if the artists provide it, also most of their projects exist is some digital form. Information will be available on the site here. (Coming soon!)

## Will there be food and drink?

Pizza will be served as well as select Domestic Beer and Wine. Guests are also welcome/encouraged to BYOB or a food item if they wish. Also there are options available in town should you seek further sustenance. If you’re bringing food just give us a heads up in RSVP so we can plan table space.

## Can I camp?

Yes! Bring your own tent. Spaces are first come first serve. Prepare for planning to dress/camp warm October can be chilly at night. There will be a fire burning somewhere if you get cold. Hotel Lodging (If you wish.)

There are a few hotels via a short drive or taxi.

[Park Ridge Marriot](https://www.reservations.com/hotel/marriott-park-ridge?msclkid=c34e196eb728160b7c197ba30d0fc996&rmcid=tophotels6&utm_campaign=Top%20Hotels%206&utm_content=Park%20Ridge%20Marriott_1739152&utm_medium=cpc&utm_source=bing&utm_term=Marriott%20Park%20Ridge%20NJ)

[Pearl River Hilton](https://www.reservationcounter.com/hotels/show/5fa86a7/hilton-pearl-river-pearl-river-ny/?cid=sem%3A%3ATPRC%3A%3ABA%3A%3AReservation%20Counter%20%3E%20US%20%3E%20Northeast%20%3E%20New%20York%20%3E%20New%20York%20City%20Area%3A%3AUS%20%3E%20New%20York%20%3E%20Pearl%20River%20%3E%20Hilton%20Pearl%20River%20%3E%20did-5fa86a7%3A%3Apearl%20river%20hilton%20new%20york%3A%3Ae&creative=78752693842143&device=c&iv_=__iv_m_e_c_78752693842143_k_78752633545968_w_kwd-78752633545968%3Aloc-4116_g_1260040469319696_n_o_e__h_99212_ii_59272_p_2_b_be_d_c_vi__&msclkid=84bb15648d231835132277a93762d8fa&utm_campaign=Reservation%20Counter%20%3E%20US%20%3E%20Northeast%20%3E%20New%20York%20%3E%20New%20York%20City%20Area&utm_medium=cpc&utm_source=bing&utm_term=pearl%20river%20hilton%20new%20york)

## Transportation Options

The event is walkable from NJ transit Park Ridge station. on the Pascack Valley Line. Driveway Parking is limited, street parking is available.

The last train from Park Ridge Station heads back at 8:28PM!

The next later option is an Uber to Ridgewood station and which run until 12:30AM.

The next next option is taking an Uber straight to Hoboken to catch the Path.

## <center>!!!!NO DRINKING AND DRIVING PLEASE!!!!</center>
`